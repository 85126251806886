import { Link } from 'gatsby';
import React from 'react';
import '../styles/style.scss';

const ThankYouPage = () => {
    return (
        <>
            <section className="hero is-fullheight-with-navbar">
                <div className="hero-body">
                    <div className="container has-text-centered is-max-desktop">
                        <img
                            src="/ranking-fotowoltaiki-logo-web.svg"
                            alt="Ranking Fotowoltaiki - logo"
                            style={{ width: '200px' }}
                        />
                        <h1 className="mt-5 title is-4 h-acc">Dziękujemy za zapisanie się</h1>
                        <p className="description mb-3">
                            Odezwiemy się do Ciebie przed publicznym startem serwisu!
                        </p>
                        <Link to="/">
                            <button className="is-primary button">Wróć</button>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ThankYouPage;
